// extracted by mini-css-extract-plugin
export var aArrow1 = "nb";
export var aArrow2 = "ob";
export var aEmpty = "db";
export var aLeft = "sb";
export var aRight = "ub";
export var aTagAnnounce = "kb";
export var aTagClose = "jb";
export var aTagEntry = "hb";
export var aTagFree = "mb";
export var aTagLottery = "lb";
export var aTagPayment = "ib";
export var aText = "pb";
export var aTitle = "eb";
export var aWrap = "rb";
export var animationMarquee = "wb";
export var infinityScrollLeft = "tb";
export var infinityScrollRight = "vb";
export var pItemBatch = "fb";
export var pItemBatchAbs = "gb";
export var pSlideItemList = "qb";