import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-slide-item-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"
// import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "@css/vender/slick-theme.css";
import { useEventListener } from "@hooks/useEventListener"

function SlideItemList({ data }) {
    const { posts, cates, catMenu } = data
    const [categorizePostData,setCategorizePostData] = useState({})
    const [cellWidth, setCellWidth] = useState(240)
    const [cellMaxCount, setCellMaxCont] = useState(1)
    const [isResize, setIsResize] = useState(0)

    const q = useStaticQuery(graphql`
        fragment SlideThumbnail on ImageSharp {
            gatsbyImageData(
                width: 240
                placeholder: BLURRED
                formats: [AUTO]
                quality: 100
                layout: FULL_WIDTH
            )
        }
        query {
            dammyItem : file(relativePath: { eq: "Common__img__empty.png" }) {
                childImageSharp {
                    ...SlideThumbnail
                }
            }
            all : file(relativePath: { eq: "Index__img__all.jpg" }) {
                childImageSharp {
                    ...SlideThumbnail
                }
            }
            category : file(relativePath: { eq: "Index__img__category.jpg" }) {
                childImageSharp {
                    ...SlideThumbnail
                }
            }
            news : file(relativePath: { eq: "Index__img__news.jpg" }) {
                childImageSharp {
                    ...SlideThumbnail
                }
            }
        }
    `)

    const makePost = ({node,i}) => {
        if( !node ) return
        if( node.isButton ) {
            console.log('>=>',node)
            return
        }
        let { thumbnail, databaseId, slug} = node
        let max = cellMaxCount
        const styles = {
            width : `calc( 100vw / ${max})`
        }
        return (
            <li key={`${i}_slug`} style={styles}>
                <TransitionLink className={css.aInner} to={`/item/${slug}`}>
                    { thumbnail ?
                        <Image data={thumbnail.node.localFile.childImageSharp} />
                        :
                        <Image data={q.dammyItem.childImageSharp} />
                    }
                </TransitionLink>
            </li>
        )
    }
    const sortPostByCats = () => {
        const result = {}
        const result2 = []
        const menuData = catMenu.menuItems.nodes
        for(let i in posts.edges){
            const { node } = posts.edges[i]
            const mainCats = node.acf_item.mainCategory
            console.log("===>cat",node.acf_item.mainCategory)
            let catNodes = node.cat.nodes

            if( mainCats && mainCats.length > 0 ){
                let mainCatNodes = []
                mainCats.map((v)=>{
                    mainCatNodes.push(v)
                    // console.log('main===>',v, node.title)
                })
                // console.log('main===>', mainCatNodes, catNodes)
                catNodes = mainCatNodes
            }

            catNodes.map(({name},i)=>{
                if( !result[name] ){
                    result[name] = []
                }
                console.log(`===>${i}${name}`,node.acf_item.isHideTop)
                if( !node.acf_item.isHideTop ){
                    console.log(`===>a${i}${name}`,mainCats)
                    // if( mainCats && mainCats.length > 0 ){
                        // mainCats.map((v)=>{
                        //     result[v.name].push(node)
                        // })
                    // } else {
                    result[name].push(node)
                }
                
            })
        }
        menuData.map((v,i)=>{
            const { label } = v
            let posts = result[label]
            console.log("===>l",label, posts)
            if( posts ){
                let sa = cellMaxCount - posts.length
                const addPosts = []
                if( sa > 0){
                    for(let i = 0; i < sa; i ++){
                        addPosts.push(posts[i%posts.length])
                    }
                }
                result2.push([...posts, ...addPosts])
            }
        })
        setCategorizePostData(result2)
    }

    useEffect(()=>{
        if( typeof window !== "undefined" ){
            const w = window.innerWidth
            let maxCount = Math.floor(w / cellWidth)
            if(w < 768){
                maxCount = 2
            }
            setCellMaxCont(maxCount)
        }
    },[isResize])

    useEffect(()=>{
        sortPostByCats()
    },[data, cellMaxCount, isResize])

    useEffect(()=>{
        console.log('=>',categorizePostData, Object.keys(categorizePostData))
    },[categorizePostData])

    const timerRef = useRef()
    useEventListener('resize', ()=>{
        if( !timerRef.current ){
            clearTimeout(timerRef.current)
        }
        timerRef.current = setTimeout(()=>{
            setIsResize(isResize + 1)
        },100)
    })

    const makeButtons = ({data,direction}) => {
        console.log("===>", direction)
        const doms = []
        const styles = {
            width : `calc( 100vw / ${cellMaxCount})`
        }
        for(let j = 0; j < cellMaxCount/data.length; j++ ){
            for(let i in data){
                let {to, img} = data[i]
                doms.push(
                    <li key={`${i}_${j}`} style={styles}>
                        <TransitionLink className={css.aInner} to={to}>
                            <Image data={img.childImageSharp} />
                        </TransitionLink>
                    </li>
                )
            }
        }
        return(
            <div className={css.aWrap}>
                <ul className={ direction === 1 ? css.aLeft : css.aRight }>
                    {doms}
                </ul>
                <ul className={ direction === 1 ? css.aLeft : css.aRight }>
                    {doms}
                </ul>
            </div>
        )
    }

    return (
        <div className={css.pSlideItemList}>
            { posts.edges.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <>
                    { categorizePostData &&
                        Object.keys(categorizePostData).map( (key,i) => {
                            return(
                                <div className={css.aWrap} key={i}>
                                    <ul className={ i % 2 ? css.aLeft : css.aRight}>
                                        { categorizePostData[key].map((node, i) => makePost({node,i}) )}
                                    </ul>
                                    <ul className={ i % 2 ? css.aLeft : css.aRight }>
                                        { categorizePostData[key].map((node, i) => makePost({node,i}) )}
                                    </ul>
                                </div>
                            )
                        })
                    }
                    { makeButtons({
                        data: [{
                            to: '/items/',
                            img: q.all,
                        },{
                            to: '/category/',
                            img: q.category,
                        },{
                            to: '/news/',
                            img: q.news,
                        }],
                        direction: Object.keys(categorizePostData).length % 2
                    })}
                </>
            }
        </div>
    )
}

export { SlideItemList }