import * as React from "react"
import { graphql, navigate} from "gatsby"
// import IndexBlcok from "@blocks/Index"
import Seo from "@atoms/Seo"
import { SlideItemList } from "@projects/SlideItemList"
import * as Spacer from "@atoms/Spacer"
function IndexPage({data})
{
    console.log('===>', data)
    return(
        <>
            <Seo/>
            <Spacer.Header/>
            <main>
                <SlideItemList data={data}/>
            </main>
        </>
    )
}

export const pagerQuery = graphql`
    query {
        cates: allWpCategory {
            edges {
                node {
                    id
                    name
                }
            }
        }
        posts : allWpPost(
            sort: {order: DESC, fields: date}
            limit: 9999

            filter: {
                acf_item: {isHide: {ne: true}}
            }
        ) {
            edges {
                node {
                    ...Item
                }
            }
        }
        catMenu: wpMenu(name: {eq: "トップとカテゴリページ"}) {
            menuItems {
              nodes {
                id
                label
                order
              }
            }
        }
    }
`
export default IndexPage
